import React from 'react';
import './App.css';

function App() {
  return (
    <div className="App">
      <img 
        src="https://tr.rbxcdn.com/e85ac5e43b26cdddddc54fb28f65830c/420/420/Hat/Webp" 
        alt="Single Hat" 
        className="centered-image"
      />
    </div>
  );
}

export default App;
